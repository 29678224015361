<template>
  <div class="lj-footer">
    <!-- <div class="information">
      <span class="driver">本产品由灯塔教育专利智能专业匹配算法驱动</span>
      <span class="patent-number">（专利号：201810642977.1）</span>
      <span class="corporation">四川智辉山河教育科技有限责任公司</span>
      <a
        href="http://www.beian.miit.gov.cn"
        title="网站备案"
        target="_blank"
        class="record"
        >蜀ICP备16031008号-1</a
      >
      <p class="contact phone">联系电话：028-67873357-转分机号2</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'LjFooter'
}
</script>

<style lang="less" scoped>
.lj-footer {
  text-align: left;
}
.phone {
  padding: 0.2rem 0 0 0;
  color: #fff;
  text-align: left;
}
</style>
