/**
 * 咨询面板
 */

import { Service } from '@/utils/Service'

//请求问题列表
export function getIssueList (data) {
  return Service({
    url: '/consulting/getissuestudent/',
    data: data
  })
}

// 提出新问题
export function postNewIssue (data) {
  return Service({
    url: '/consulting/proposeissue/',
    data: data
  })
}

// 问题反馈
export function feedBackIssue (data) {
  return Service({
    url: '/consulting/feedbackissue/',
    data: data
  })
}

// 删除问题
export function deleteIssue (data) {
  return Service({
    url: '/consulting/deleteissue/',
    data: data
  })
}
