<template>
  <div>
    <lj-nav></lj-nav>
    <div class="main">
      <div style="padding: 0px" class="main-title">报告解读与生涯服务咨询</div>
      <div class="main-parts-wrapper">
        <el-collapse class="collapse-panel">
          <el-collapse-item>
            <template slot="title">
              <div class="collapse-title">
                点击添加问题咨询<i style="padding-left: 0.3rem" class="el-icon-edit"></i>
              </div>
            </template>
            <div class="collapse-detail">
              <div class="login-wrapper">
                <div class="login">
                  <div class="login-title">
                    <span class="lj-icon-compass">报告解读与生涯服务咨询</span>
                  </div>
                  <hr />
                  <p style="margin: 0">欢迎使用灯塔智能生涯规划与数据分析系统</p>
                  <p style="margin-bottom: 0">希望我们能给您带来帮助！</p>
                  <hr />
                  <div class="lj-form login-form">
                    <fieldset>
                      <div class="lj-form-group">
                        <input class="lj-radius" type="text" placeholder="请输入您的问题" required=""
                          v-model="questionForm.question" />
                      </div>
                      <div class="lj-form-group">
                        <textarea class="lj-radius" v-model="questionForm.describe" style="min-height: 60px;"
                          placeholder="请输入您的问题详细描述"></textarea>
                      </div>
                      <p>
                        <a class="lj-btn lj-btn-primary lj-btn-block" @click="onCreateQuestion"
                          style="margin-bottom: 1rem">
                          提问
                        </a>
                        <a class="lj-btn lj-btn-third lj-btn-block" @click="onCancelQuestion"
                          style="margin-bottom: 1rem; background: #209ff0">
                          清空
                        </a>
                      </p>
                    </fieldset>
                  </div>

                </div>
              </div>

              <!-- <el-form label-position="top" label-width="40px" :model="questionForm">
                <el-form-item label="问题">
                  <el-input v-model="questionForm.question"></el-input>
                </el-form-item>
                <el-form-item label="问题描述">
                  <el-input type="textarea" v-model="questionForm.describe"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onCreateQuestion">创建问题</el-button>
                  <div style="padding: 5px 0" class="button-wrapper">
                    <a class="lj-btn lj-btn-primary lj-btn-block" @click="onCreateQuestion">创建问题
                    </a>
                    <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="onCancelQuestion">取消
                    </a>
                  </div>
                  <el-button @click="onCancelQuestion">取消</el-button>
                </el-form-item>
              </el-form> -->
            </div>
          </el-collapse-item>
        </el-collapse>
        <!-- <div class="part-title consultor" @click="jumpPage('/Consultor')">咨询师介绍</div> -->

        <div class="part-title">咨询问题列表</div>
        <el-collapse class="collapse-panel" accordion>
          <el-collapse-item v-for="(item, index) in questionList" :key="index">
            <template slot="title">
              <div class="collapse-question">
                <span>问题：{{ item.issue_title }}</span>
              </div>
            </template>
            <div class="collapse-detail">
              <div class="collapse-question">
                <div>描述：<p>{{ item.issue_content }}</p>
                </div>
              </div>
              <div class="msg-item" v-if="
                item.issue_answer !== null &&
                item.issue_answer !== undefined &&
                item.issue_answer !== ''
              ">
                <div class="msg-avatar">
                  <el-avatar size="small" :src="teacherUrl"></el-avatar>
                  <div>老师</div>
                </div>
                <div class="msg-detail">{{ item.issue_answer }}</div>
              </div>
              <div class="msg-item" v-if="
                item.suggestion_and_comments !== null &&
                item.suggestion_and_comments !== undefined &&
                item.suggestion_and_comments !== ''
              ">
                <div class="msg-avatar">
                  <el-avatar size="small" :src="studentUrl"></el-avatar>
                  <div>学生</div>
                </div>
                <div class="msg-detail">{{ item.suggestion_and_comments }}</div>
              </div>
              <!-- <el-form :model="sendMsg">
                <el-form-item label="编辑回复">
                  <el-input :disabled="
                    item.issue_answer === null || item.suggestion_and_comments !== null
                  " type="textarea" v-model="sendMsg.msg"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSendMsg(item.issue_id)">反馈</el-button>
                  <el-button @click="onCancelMsg">取消</el-button>
                </el-form-item>
              </el-form> -->
              <p style="font-weight: bold;color: #555;">编辑回复</p>
              <div class="lj-form login-form">
                <fieldset>
                  <div class="lj-form-group">
                    <textarea :disabled="
                      item.issue_answer === null || item.suggestion_and_comments !== null
                    " class="lj-radius" v-model="sendMsg.msg" style="min-height: 60px;" placeholder="回复"></textarea>
                  </div>
                  <div>
                    <a class="lj-btn lj-btn-primary" @click="onSendMsg(item.issue_id)" style="margin-left: 1rem">
                      反馈
                    </a>
                    <span style="width:3em;display: inline-block;"></span>
                    <a class="lj-btn lj-btn-third" @click="onCancelMsg" style="margin-right: 1rem">
                      清空</a>
                  </div>
                </fieldset>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div style="padding: 20px 30%" class="button-wrapper">
        <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="jumpPage('/ConsultUs')">返回
        </a>
      </div>
    </div>
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import {
  getIssueList,
  postNewIssue,
  feedBackIssue,
  deleteIssue
} from './../../../api/consult.js'
import LjFooter from '../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
export default {
  components: { LjFooter, LjNav },
  data() {
    return {
      teacherUrl: require('./../../../icon/teacher.png'),
      studentUrl: require('./../../../icon/student.png'),
      questionForm: {
        question: '',
        describe: ''
      },
      ruleForm: {
        //username和password默认为空
        username: '',
        password: '',
        newpassword: '',
        newpassword2: ''
      },
      sendMsg: {
        msg: ''
      },
      questionList: [
        {
          issue_content: '',
          issue_title: '',
          suggestion_and_comments: '',
          issue_answer: '',
          issue_id: ''
        }
      ]
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  mounted: function () {
    if (this.isMobile === null) {
      if (window.location.href.indexOf('#reloaded') == -1) {
        window.location.href = window.location.href + '#reloaded'
        window.location.reload()
      }
    }
    this.queryIssueList()
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    queryIssueList: function () {
      getIssueList({}).then(res => {
        if (res.rescode == 200) {
          console.log('getIssueList', res)
          this.questionList = res.issues
        }
      })
    },
    onSendMsg: function (id) {
      if (
        this.sendMsg.msg !== undefined &&
        this.sendMsg.msg !== '' &&
        this.sendMsg.msg !== null
      ) {
        feedBackIssue({
          issue_id: id,
          suggestion_and_comments: this.sendMsg.msg,
          significance: 5,
        }).then(res => {
          this.$message.success('反馈成功')
          this.queryIssueList()
          this.onCancelMsg()
        })
      }
    },
    onCancelMsg: function () {
      this.sendMsg.msg = ''
    },
    onCreateQuestion: function () {
      if (
        this.questionForm.question !== undefined &&
        this.questionForm.question !== '' &&
        this.questionForm.question != null
      ) {
        postNewIssue({
          issue_title: this.questionForm.question,
          issue_content: this.questionForm.describe
        }).then(res => {
          if (res.rescode == 200) {
            this.$message.success('询问成功，等待反馈')
            this.queryIssueList()
            this.onCancelQuestion()
          }
        })
      }
    },
    onCancelQuestion: function () {
      this.questionForm.describe = ''
      this.questionForm.question = ''
    },
    onDeleteQuestion: function (id) {
      deleteIssue({ issue_id: id }).then(res => {
        this.$message.success('删除成功')
        this.queryIssueList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/templates/home/test/index.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');

* {
  box-sizing: border-box;
}

@media only screen and (min-width: 480px) {
  .main {
    min-height: 637px;
    background: #cfd1cc;
  }
}

.collapse-panel {
  margin: 1rem 0;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .main-parts-wrapper {
    padding: 0.2rem 0.2rem;
  }
}

@media only screen and (min-width: 480px) {
  .main-parts-wrapper {
    padding: 1rem 4rem;
  }
}

.collapse-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.collapse-question {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  padding: 0 2rem;
  width: 100%;

  span {
    padding-right: 4rem;
  }
}

.collapse-detail {
  padding: 1rem 2rem;
}

.msg-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .msg-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .msg-detail {
    margin-left: 1.8rem;
    text-indent: 1.4rem;
    font-size: 1.3rem;
  }
}

.part-title {
  user-select: none;
  font-size: 2rem;
  border-bottom: 1px solid #f17c67;
  padding: 1rem 1rem 1rem;
  font-weight: 700;
  text-align: left;
}

.consultor {
  cursor: pointer;
}
</style>
