<template>
  <div class="lj-nav" style="background: #565a5d">
    <div class="lj-nav-left">
      <div class="lj-nav-item lj-nav-logo">
        <a
          class="lj-icon-compass lj-nav-item-title"
          style="color: #fff"
          @click="jumpPage('/')"
          >&nbsp;&nbsp;灯塔智能测评系统</a
        >
      </div>
      <div class="lj-nav-item">
        <a
          class="lj-nav-item-title hover-effect"
          @click="jumpPage('/')"
          style="color: #fff"
          >首页</a
        >
      </div>
      <div class="lj-nav-item">
        <a
          class="lj-nav-item-title hover-effect"
          style="color: #fff"
          @click="jumpPage('HomeIndexNewProductsManual')"
          >使用帮助</a
        >
      </div>
      <div class="lj-nav-item">
        <a
          class="lj-nav-item-title hover-effect"
          @click="jumpPage('ConsultUs')"
          style="color: #fff"
          >报告解读与生涯服务咨询</a
        >
      </div>
    </div>
    <div class="account" @mouseover="showMenu" @mouseout="hideMenu">
      <a
        class="account-title"
        style="color: #fff"
        href="#"
        :class="{
          'account-title-hover': !isMobile && isLoggedIn && menuDisplay
        }"
        @click="onMenuClick('Default')"
        @touchstart="toggleMenu"
      >
        {{ name }}&nbsp;
        <span
          class="lj-icon-caret-down account-title-caret-down"
          v-show="isLoggedIn"
          :class="{
            'account-title-caret-down-hover':
              !isMobile && isLoggedIn && menuDisplay
          }"
        ></span>
      </a>
      <div
        ref="menuWrapper"
        class="account-menu-wrapper"
        v-show="isLoggedIn && menuDisplay"
        @mouseover="showMenu"
        @mouseout="hideMenu"
      >
        <div class="account-menu" ref="menu">
          <a
            class="account-menu-item"
            @click="onMenuClick('subject')"
            @touchstart="onMenuClick('subject')"
            >学科能力测评</a
          >
          <a
            class="account-menu-item"
            @click="onMenuClick('major')"
            @touchstart="onMenuClick('major')"
            >智能专业匹配</a
          >
          <a
            class="account-menu-item"
            @click="onMenuClick('xuanke')"
            @touchstart="onMenuClick('xuanke')"
            >新高考选科</a
          >
          <a
            class="account-menu-item"
            @click="onMenuClick('consult')"
            @touchstart="onMenuClick('consult')"
            >报告解读与生涯服务咨询</a
          >
          <hr class="account-menu-hr" />
          <a
            class="account-menu-item"
            @click="onMenuClick('resetpwd')"
            @touchstart="onMenuClick('resetpwd')"
            >修改密码</a
          >
          <a
            class="account-menu-item"
            @click="LoginOut()"
            @touchend="LoginOutMobile()"
            >退出当前账户</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginout } from './../api/userinfo'
export default {
  data: function () {
    return { menuDisplay: !1, isVIP: !1, hasPaid: !1 }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    },
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    name: function () {
      return this.isLoggedIn
        ? 'identity' === this.$store.state.User.identity
          ? '系统管理员'
          : this.$store.state.User.username || '新用户'
        : '登录/注册'
    }
  },
  methods: {
    onMenuClick: function (e) {
      if (this.isLoggedIn) {
        switch (e) {
          case 'subject':
            if (this.$route.path !== '/HomeTestIndex') {
              this.jumpPage('HomeTestIndex')
            }
            break
          case 'major':
            if (this.$route.path !== '/HomeMajorStdMatchPage') {
              this.jumpPage('HomeMajorStdMatchPage')
            }
            break
          case 'xuanke':
            if (this.$route.path !== '/monixuanke') {
              this.jumpPage('monixuanke')
            }
            break
          case 'consult':
            if (this.$route.path !== '/ConsultUs') {
              this.jumpPage('ConsultUs')
            }
            break
          case 'resetpwd':
            if (this.$route.path !== '/resetpwd') {
              this.jumpPage('resetpwd')
            }
            break
        }
      }
    },
    toggleMenu: function () {
      this.menuDisplay = !this.menuDisplay
    },
    showMenu: function () {
      !this.isMobile && this.isLoggedIn && (this.menuDisplay = !0)
    },
    hideMenu: function () {
      this.menuDisplay = !1
    },
    LoginOutMobile:function(){
      loginout({}).then(res => {
            if (res.rescode == 200) {
              this.$store.commit('User/LoginOut')
              this.$router.replace({ path: '/login' })
              this.$message({
                type: 'success',
                message: '已退出登录!'
              })
            }
          })
    },
    LoginOut: function () {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 清除一下session
          loginout({}).then(res => {
            if (res.rescode == 200) {
              this.$store.commit('User/LoginOut')
              this.$router.replace({ path: '/login' })
              this.$message({
                type: 'success',
                message: '已退出登录!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../Public/resources/css/components/nav.min.css');
.lj-nav {
  box-sizing: border-box;
}
.lj-nav-left {
  float: left;
}

.new-nav-item-title {
  color: #fff;
}
</style>
